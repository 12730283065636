<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-6"
        v-model="reportFilter.startDate"
        label="วันที่เริ่ม"
        isNull>
      </sgv-input-date>

      <sgv-input-date
        class="col-6"
        v-model="reportFilter.endDate"
        label="วันที่ถึง"
        isNull>
      </sgv-input-date>
    </div>

    <ListReportDeliveryPrinter
      class=""
      :docType="docType"
      :templateType="templateType"
      @search="searchData"
      @print="printData"
      @download="downloadData">
    </ListReportDeliveryPrinter>

    <sgv-csv :items="items" :labels="csvLabels" :filename="csvFilename">
      <button type="button" class="btn btn-success mt-3">CSV</button>
    </sgv-csv>

    <div class="table-responsive mt-3" v-if="items">
      <table class="table">
        <thead>
          <tr>
            <td colspan="6">
              {{summary.count}} รายการ |
              มูลค่าสินค้า <span class="text-primary">{{summary.totalPrice}}</span> บาท |
              VAT <span class="text-warning">{{summary.vatPrice}}</span> บาท |
              รวมทั้งสิ้น <span class="text-success">{{summary.netPrice}}</span> บาท
            </td>
          </tr>
          <tr>
            <th>รหัส</th>
            <th>ชื่อ</th>
            <th>เลขประจำตัวผู้เสียภาษี</th>
            <th class="text-center">ยอดรวม</th>
            <th class="text-right">Vat</th>
            <th class="text-right">สุทธิ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>{{item.code}}</td>
            <td>{{item.name}}</td>
            <td>{{item.taxCode}}</td>
            <td class="text-right">{{item.totalPrice}}</td>
            <td class="text-right">{{item.vatPrice}}</td>
            <td class="text-right">{{item.netPrice}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_REPORT_DEBTOR_BY_INTERVAL,
} from './graph'
import ListReportDeliveryPrinter from './ListReportDeliveryPrinter.vue'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}ReportDebtorByInterval`,
      items: [],
      rows: [],
      reportFilter: {
        startDate: '',
        endDate: '',
      },
      rFields: ['reportFilter'],
      url: `/doc/${this.docType}/report/delivery/contact/print`,
      csvLabels: {
        code: {title: 'รหัส'},
        name: {title: 'ชื่อ'},
        taxCode: {title: 'เลขประจำตัวผู้เสียภาษี'},
        totalPrice: {title: 'มูลค่า'},
        vatPrice: {title: 'ภาษี'},
        netPrice: {title: 'รวมสุทธิ'},
      }
    }
  },
  computed: {
    csvFilename () {
      return `ลูกหนี้การค้า_${this.reportFilter.startDate}_${this.reportFilter.endDate}`
    },
    summary () {
      const count = this.rows.length
      const totalPrice = this.rows.reduce((t,v) => t += +v.totalPrice, 0)
      const vatPrice = this.rows.reduce((t,v) => t += +v.vatPrice, 0)
      const netPrice = this.rows.reduce((t,v) => t += +v.netPrice, 0)
      return {
        count,
        totalPrice: this.$form.addComma(totalPrice),
        vatPrice: this.$form.addComma(vatPrice),
        netPrice: this.$form.addComma(netPrice)
      }
    }
  },
  methods: {
    serializeInput () {
      const obj = {}
      if (this.reportFilter.startDate) obj.startDate = this.$date.format(this.reportFilter.startDate).utc
      if (this.reportFilter.endDate) obj.endDate = this.$date.format(this.reportFilter.endDate, 0, 1).utc

      return obj
    },
    serializeData (contacts) {
      return contacts
    },
    searchData () {
      this.setRetaining()
      this.items = []

      const input = this.serializeInput()

      this.$apollo.query({
        query: LIST_REPORT_DEBTOR_BY_INTERVAL(this.templateType),
        variables: {
          docType: this.docType,
          ...input
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.rows = res.data.contacts
        this.items = this.serializeData(res.data.contacts)
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
    downloadData () {
      this.$axios.get(this.url, {
        params: this.serializeInput(),
        responseType: 'blob'
      })
      .then(res => {
        let filename = 'invoice_report.pdf'
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(res.data)
        a.download = filename
        a.click()
      }).catch(() => {
        this.$toasted.global.error("ไม่สามารถโหลดได้")
      })
    },
    printData (deviceId) {
      if (!deviceId) {
        this.$toasted.global.error("โปรดเลือกเครื่องพิมพ์")
        return
      }

      this.$axios.get(this.url, {
        params: {
          ...this.serializeInput(),
          deviceId
        }
      })
      .then(() => {
        this.$toasted.global.success("ส่งไปยังเครื่องพิมพ์")
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถพิมพ์หรือบันทึกไฟล์ได้")
      })
    },
  },
  watch: {
    reportFilter: {
      handler () {
        this.setRetaining()
      },
      deep: true
    }
  },
  components: {
    ListReportDeliveryPrinter
  }
}
</script>

<style lang="css" scoped>
</style>
