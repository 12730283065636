<template>
  <div class="">
    <div class="form-row">
      <sgv-input-select
        class="col-12"
        :options="options"
        v-model="selected"
        select="value">
        <template slot-scope="item">{{item.text}}</template>
      </sgv-input-select>
    </div>

    <component
      :is="selected"
      :docType="docType"
      :templateType="templateType">
    </component>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import ListReportGroup from '@/views/doc_core/components/ListReportGroup'
import ListReportLedger from '@/views/doc_core/components/ListReportLedger'
import ListReportDeliveryByInvoice from './ListReportDeliveryByInvoice.vue'
import ListReportInvoiceGlobalHouse from './ListReportInvoiceGlobalHouse.vue'
import ListReportDeliveryByInterval from './ListReportDeliveryByInterval.vue'
import ListReportDeliveryByVehicle from './ListReportDeliveryByVehicle.vue'
import ListReportInventory from './ListReportInventory.vue'
import ListReportInventoryGraph from './ListReportInventoryGraph.vue'
import ListReportDebtorByInterval from './ListReportDebtorByInterval.vue'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}Report`,
      rFields: ['selected'],
      options: [
        {text: 'รายงานกลุ่ม', value: 'ListReportGroup', role: `doc:${this.docType}:read`},
        {text: 'รายงานบัญชี', value: 'ListReportLedger', role: `doc:${this.docType}:read`},
        {text: 'รายงานจัดส่ง', value: 'ListReportDeliveryByInvoice', role: `doc:${this.docType}:read`},
        {text: 'รายงานจัดส่ง (GlobalHouse)', value: 'ListReportInvoiceGlobalHouse', role: `doc:${this.docType}:report:invoice:globalhouse`},
        {text: 'รายงานเขตการขาย', value: 'ListReportDeliveryByInterval', role: `doc:${this.docType}:read`},
        {text: 'รายงานยานพาหนะ', value: 'ListReportDeliveryByVehicle', role: `doc:${this.docType}:read`},
        {text: 'รายงานสินค้า', value: 'ListReportInventory', role: `doc:${this.docType}:read`},
        {text: 'รายงานลูกหนี้', value: 'ListReportDebtorByInterval', role: `doc:${this.docType}:read`},
        {text: 'กราฟสินค้า', value: 'ListReportInventoryGraph', role: `doc:${this.docType}:read`},
      ],
      selected: 'ListReportGroup'
    }
  },
  watch: {
    selected: 'setRetaining'
  },
  components: {
    ListReportGroup,
    ListReportLedger,
    ListReportDeliveryByInvoice,
    ListReportInventory,
    ListReportInventoryGraph,
    ListReportDeliveryByInterval,
    ListReportDeliveryByVehicle,
    ListReportInvoiceGlobalHouse,
    ListReportDebtorByInterval
  }
}
</script>

<style lang="css" scoped>
</style>
